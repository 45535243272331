import styled from 'styled-components';

const OL = styled.ol`
  list-style-type: decimal;
  padding-left: ${({ theme }) => theme.spacing.baseUnit};

  li {
    margin-bottom: ${({ theme }) => theme.spacing.baseUnit};
  }
`;

export default OL;
