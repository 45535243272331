import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Block from '../components/Block';
import H2 from '../components/H2';
import H4 from '../components/H4';
import P from '../components/P';
import OL from '../components/OL';
import Grid from '../components/Grid';
import Cell from '../components/Cell';

const IndexPage = () => (
  <Layout>
    <SEO title="Start" />

    <Block
      py={{ mobile: 'baseUp6', smallTablet: 'baseUp10' }}
      backgroundColor="green"
    />

    <Grid pt="baseUp4" pb="baseUp8" px="baseUnit" halign="center">
      <Cell size={{ mobile: 1, smallTablet: 2 / 3 }}>
        <H2>Avtalsvillkor</H2>
        <P>Senast ändrad: Juni 15, 2017</P>

        <P>
          Tack för att du använder våra produkter och tjänster (“Tjänsterna”).
          Tjänsterna tillhandahålls av Samir Fors Enskild Firma och Christoffer
          Ekeroth Enskild Firma. (“Företaget”), belägna på Tranebergsvägen 22,
          167 44, Bromma och Svartviksslingan 102, 167 39, Bromma.
        </P>
        <P>Genom att använda Tjänsterna, samtycker du till dessa villkor.</P>

        <H4>Allmänt om avtalet</H4>
        <P>
          Genom att göra en skriftlig eller elektronisk beställning förvärvar
          Kunden endast rätt att nyttja Tjänsterna. Med skriftlig beställning
          menas en signerad offert eller ett avtal. Med elektronisk beställning
          menas en beställning som gjorts via Företagets hemsidor, där kunden
          aktivt markerat att samtliga villkor godkänts.
        </P>
        <P>
          Kunden godkänner härmed elektronisk kommunikation som medel att ingå
          och avsluta juridiskt bindande avtal, göra beställningar eller skapa
          andra registreringar. Kunden godkänner härmed även elektronisk
          leverans av meddelanden, regler och transaktionsunderlag för kommande
          fakturering.
        </P>
        <P>
          Dessutom avsäger Kunden sig härmed alla rättigheter enligt eventuella
          lagar som kräver icke-elektronisk original namnteckning av avtal och
          accepterar även att överenskommelser gjorda via mailkorrespondens är
          giltiga.
        </P>

        <H4>Produkt och tjänstebeskrivning</H4>
        <P>
          Företaget tillhandahåller tjänster och mjukvara för internetbaserad
          bokning av träningstider. En tjänst kan bestå av ett konsultuppdrag
          och/eller ett löpande abonnemang gällande drift och service av avtalad
          it-lösning / plattform. En produkt kan även bestå av moduler till vald
          lösning. Dessa moduler tillhandahålls av Företaget. Innehållet i
          produktpaketering såväl som prissättning kan förändras. Avisering om
          en sådan förändring sker normalt elektroniskt, 30 dagar innan
          ändringen träder i kraft.
        </P>
        <H4>Användande av Tjänsterna</H4>
        <P>
          Missbruka inte Tjänsterna. Exempelvis, försök inte förhindra
          funktionen av Tjänsterna eller komma åt dem genom annan metod än den
          av Företaget angivna. Företaget förbehåller sig rätten att stänga av
          Kundens tillgång till tjänsterna vid misstanke om missbruk.
        </P>

        <P>
          Användande av Tjänsterna ger inte Kunden ägandeskap av några
          immateriella rättigheter av Tjänsterna.
        </P>
        <H4>Kontaktperson</H4>
        <P>
          Det åligger Kunden att alltid ha en, för kontakten med Företaget,
          ansvarig kontaktperson, som har befogenhet att lämna för Kunden
          bindande besked i de frågor som avser uppdragets utförande.
        </P>
        <H4>Avtalsperiod & Uppsägning på Tjänsterna</H4>
        <P>
          Alla tjänster är löpande avtal. Om inget annat avtalats, löper avtalet
          för driften av Tjänsterna kvartalsvis på 3 månader eller den avtalstid
          som kunden och Företaget blivit eniga om i ett eget separat avtal. Om
          avtalet inte sägs upp, och uppsägning inte är Företaget tillhanda
          senast 1 månad (30 dgr) före avtalstidens utgång, förlängs avtalet
          automatiskt med 3 månader eller den avtalstid som kunden och Företaget
          enats om i ett eget separat avtal . Om uppsägningen görs efter
          ingången av en ny avtalsperiod så sker ingen återbetalning för den
          innevarande perioden.
        </P>

        <P>
          Uppsägning av avtalet måste göras elektroniskt och det är kundens
          ansvar att dokumentera att uppsägningen kommit fram till Företaget.
          När Företaget mottar en uppsägning så skickas en elektronisk
          bekräftelse på att Företaget mottagit uppsägningen. Normalt sker detta
          inom 48h.
        </P>

        <P>
          Företaget accepterar inte uppsägningar per telefon, fax eller brev.
        </P>
        <H4>Trafikavgifter och andra tilläggstjänster</H4>
        <P>
          I Företagets abonnemang ingår normala volymer av exempelvis trafik,
          lagringsutrymme, nyhetsbrevsutskick, etc. Om en Kund löpande visar sig
          ha behov av betydande högre volym än vad som är avtalat, kommer
          Företaget ålägga kunden kostnaden för ökad volym. Exempelvis högre
          gräns på antal skickade mail.
        </P>

        <P>
          Företaget förbehåller sig rätten att uppjustera denna form av
          volymavtal där den ökande volymen är varande. Som regel
          efterfaktureras inte den ökade volymen utan avtalet justeras permanent
          upp för kommande avtalsperiod. Om det sker förändringar i Tjänsterna,
          där exempelvis trafik eller lagringsutrymme minskar, så är det kundens
          ansvar att kontakta Företaget för avtalsjustering.
        </P>
        <H4>Betalning</H4>
        <P>
          Betalning sker kvartalsvis i förskott. Betalning sker mot faktura med
          30 dagar netto. Alla priser är i regel angivna exklusive
          mervärdesskatt.
        </P>
        <H4>Utebliven betalning</H4>
        <P>
          Vid utebliven betalning har Företaget rätt att stänga av Kundens
          tillgång till Tjänsterna, 10 dagar efter det att fakturan förfallit.
          Material på Kundens konto hos Företaget lagras i 90 dagar efter
          stängning. Företaget äger rätt att ta ut betalningspåminnelser,
          inkassokostnader, indrivningskostnader samt därutöver dröjsmålsränta
          enligt räntelagen (SFS 1975:635). Företaget äger rätten att debitera
          avgift för Tjänsterna även fram till Tjänsternas upphörande.
        </P>
        <H4>Invändning mot faktura för tjänst och drift</H4>
        <P>
          Eventuella invändningar mot faktura för utförd tjänst
          (konsultuppdrag), skall ske inom 14 dagar från fakturadatum. Därefter
          förlorar Kunden rätten att göra invändning mot fakturan. Har Kunden i
          tid invänt mot fakturan och anfört saklig grund mot debiteringen,
          skall Företaget medge anstånd med betalningen av det tvistiga
          beloppet. Medges anstånd utgår dröjsmålsränta på den del av det
          tvistiga beloppet som Kunden är skyldig att betala.
        </P>
        <P>
          Eventuella invändningar mot faktura för avtalad kommande, ej nyttjad,
          drift, eller beställd, men ej nyttjad, produkt, kan ske till och med
          det datum då tjänsten börjar levereras/aktiveras. Har Kunden i tid
          invänt mot fakturan och anfört saklig grund mot debiteringen, skall
          Företaget medge anstånd med betalningen av det tvistiga beloppet.
          Medges anstånd utgår dröjsmålsränta på den del av det tvistiga
          beloppet som Kunden är skyldig att betala.
        </P>
        <H4>Överlåtelse av avtal</H4>
        <P>
          Kunden äger endast rätt att överlåta detta avtal till ny part (med
          annat organisationsnummer), efter medgivande av Företaget.
          Överlåtelsen och medgivandet skall ske skriftligt av bägge parter. Den
          frånträdande Kunden är inte betalningsskyldig för förpliktelser som
          uppkommer efter överlåtelsetidpunkten. Den tillträdande Kunden är inte
          betalningsskyldig för förpliktelser som uppkommit innan
          överlåtelsetidpunkten. Företaget har rätt att överlåta sin del av
          avtalet, inklusive dess förpliktelser och skyldigheter, utan Kundens
          godkännande.
        </P>
        <H4>Ändring av villkor</H4>
        <P>
          Dessa Allmänna villkor gäller tills vidare. Villkorsändring skall
          aviseras elektroniskt 30 dagar innan ändringen träder i kraft.
        </P>
        <H4>Avtalets upphörande</H4>
        <P>
          Upphör avtalet att gälla upphör också Kundens rätt att använda
          Tjänsterna och Företaget får omedelbart stänga av Kundens tillgång
          till Tjänsterna.
        </P>
        <H4>Företagets ansvar</H4>
        <P>
          Företaget ansvarar för driften av Tjänsterna, vilket bland annat
          innefattar regelbunden backup av Kundens hos Företaget lagrade data,
          konfiguration av de servrar och kringtjänster som krävs för att
          tillhandahålla Tjänsterna samt åtgärder av driftstörningar och fel i
          programvaran som gör att Tjänsterna inte fungerar som avtalat. (Se
          även avsnittet “Driftsäkerhet” i detta avtal.) Observera att
          vidareutveckling av tjänsten, det vill säga utveckling av
          funktionalitet inte omfattas av Företagets ansvar.
        </P>
        <P>
          För att värna om Kundens integritet hanterar Företaget all information
          om Kunden som konfidentiell, dock kan Företaget via berörda
          myndigheter föreläggande åläggas att lämna ut sådana uppgifter om
          Kunden.
        </P>
        <H4>Kundens ansvar</H4>
        <P>
          Kunden förbinder sig att följa svensk lag, detta gäller även länkade
          objekt, som t.ex bilder, filmer etc, som strider mot dessa. Kunden
          ansvarar för all information denne publicerar på Tjänsten. Kunden är
          förbjuden att direkt eller indirekt överföra oönskade massutskick
          (SPAM.). Kunden förbinder sig att inte använda Företagets system på
          ett sätt som negativt påverkar Företagets system eller övriga
          internetanvändare, exempelvis genom att starta repeterande processer i
          applikation med enda syfte att belasta systemet.
        </P>
        <H4>Ansvarsbegränsning</H4>
        <P>
          Företaget ansvarar inte i något fall för indirekt skada såsom
          utebliven vinst eller nytta, minskad produktion eller omsättning,
          förlust av data, skada på tredje mans egendom, hinder att uppfylla
          förpliktelser gentemot tredje man, eller annan följdskada samt skada
          som icke rimligen kunnat förutses av Företaget. Företaget kan inte
          heller hållas skadeståndsskyldig för de fall en användare, vare sig
          denne är behörig eller obehörig, gör intrång i Kundens eller annans
          datorresurs och skaffar tillgång till, förstör eller förvanskar data
          eller information. Vid förekommet fall finns sju dagars backup på all
          data. Företagets skadeståndsansvar är under alla förhållanden,
          inkluderat direkt skada, begränsat till högst två basbelopp.
        </P>
        <P>
          Krav på skadestånd ska framställas skriftligen inom två månader efter
          det att skadan upptäckts eller borde ha upptäckts.
        </P>
        <H4>Force Majeure</H4>
        <P>
          Företaget är befriat från påföljder om fullgörandet av visst åtagande
          förhindras eller väsentligen försvåras av omständighet som part ej
          rimligen kunnat råda över eller förhindra. Som befriande omständighet
          skall bl.a. anses arbetskonflikt, krig, fel i extern
          datakommunikation, blixtnedslag, eldsvåda, extrema väderförhållanden,
          myndighetsbestämmelse eller annan offentlig reglering, allmän knapphet
          på varor, transporter, energi eller annan liknande omständighet.
        </P>
        <H4>Hävningsgrunder</H4>
        <P>
          Gör en part sig skyldig till väsentligt kontraktsbrott och har denne
          ej vidtagit rättelse senast 30 dagar efter det att denne erhållit
          skriftlig anmaning härom från den andra parten, har denne rätt att med
          omedelbar verkan skriftligen säga upp avtalet.
        </P>
        <H4>Tillämplig lag och Tvist</H4>
        <P>
          Tvister angående tolkning eller tillämpning av detta avtal och därmed
          sammanhängande rättsförhållanden ska slutligt avgöras av skiljemän
          enligt svensk lag, enligt Stockholms Handelskammares
          Skiljedomsinstituts regler för förenklat skiljeförfarande.
          Skiljeförfarandet skall äga rum i Stockholm. Ovannämnda ska dock inte
          hindra Företaget från att hävda sin äganderätt och sina immateriella
          rättigheter (inklusive, men inte begränsat till, begäran om
          interimistiska förelägganden) i andra jurisdiktioner, och i enlighet
          med förfaranden och villkor som tillämpas i dessa jurisdiktioner.
          Företaget äger dock alltid rätt att vända sig till allmän domstol för
          indrivning av klar och förfallen fordran.
        </P>
        <H4>Driftsäkerhet</H4>
        <P>
          Efter att Tjänsten har färdigställts och satts i drift ansvarar
          Företaget för att se till att Tjänsten är tillgänglig, såvida inget
          annat överenskommits. Med tillgänglighet avses i detta fall att
          tjänsten fungerar som avtalat, med följande begränsningar:
        </P>
        <OL>
          <li>
            Företaget förbinder sig endast att göra Tjänsten tillgänglig från
            sina egna servrar. Företaget ansvarar ej för övriga förhållanden som
            krävs för att Kunden eller tredje part ska ha tillgång till
            Tjänsten, såsom tillgång till Internet, tillgång till lämplig
            program- och hårdvara, och så vidare.
          </li>
          <li>
            Företaget ansvarar ej för fel som inte är direkt kopplade till
            Tjänstens tillgänglighet, såsom nedsatt prestanda eller
            handhavandefel hos användare.
          </li>
        </OL>

        <P>
          Företaget reserverar sig för att Tjänsten kan vara otillgänglig under
          kortare perioder (s.k. “nertid”), begränsat till totalt 72 timmar per
          kalendermånad. Har Tjänsten under en kalendermånad varit otillgänglig
          längre än totalt 72 timmar har Kunden rätt att häva eventuella
          debiteringar från Företaget som avser drift.
        </P>

        <P>
          Om Tjänsten blir otillgänglig ansvarar Kunden för att uppmärksamma
          Företaget på detta inom 24 timmar. Underlåter sig Kunden att informera
          Företaget inom den angivna tidsperioden skall den tid som tjänsten
          varit otillgänglig innan Företaget kontaktades inte räknas till
          månadens nertid.
        </P>
      </Cell>
    </Grid>
  </Layout>
);

export default IndexPage;
